import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import DesktopActivationView from './DesktopActivationView'
import routes from '../../containers/Router/routes'
import config from '../../config'

const DesktopActivation: FC = () => {
  const searchParams = useLocation().search
  const params = new URLSearchParams(searchParams)
  const history = useHistory()
  const cookiesPolicyAccepted = params.get('cookie_policy_accepted')

  const [scriptApplied, setScriptApplied] = useState(false)
  const [loading, setLoading] = useState(true)

  const onGifLoaded = useCallback((): void => {
    const timeout = setTimeout(() => {
      clearTimeout(timeout)
      setLoading(false)
    }, 3500)
  }, [])

  useEffect(() => {
    function getCookie (name: string) {
      const pattern = RegExp(name + '=.[^;]*')
      const matched = document.cookie.match(pattern)
      // cookie doesn't exist
      if (!matched) return ''
      // return cookie
      const cookie = matched[0].split('=')
      return cookie[1]
    }

    function setCookie () {
      const btrid = getCookie('_btrid')
      const tribalDataId = params.get('emailHash')

      const dscript = document.createElement('script')
      dscript.src = ('https://dmp.citiservi.es/pxtrack.js?mode=external&m=2&sid=2777&p=1n7h88h3&i=2778&bt=' + btrid + '&id=' + tribalDataId)
      document.head.appendChild(dscript)
      setScriptApplied(true)
    }

    async function activateDesktopUser () {
      try {
        const emailHash = params.get('emailHash')
        if (!emailHash) return
        const response = await fetch(
          `${config.cfLink}?emailHash=${emailHash}`,
          {
            method: 'POST'
          })
        const json = await response.text()
        console.log('CF response:', json)
      } catch (e) {
        console.error('Error:', e)
      }
    }

    if (!cookiesPolicyAccepted || (cookiesPolicyAccepted !== 'yes')) return
    setCookie()
    activateDesktopUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (scriptApplied && !loading) history.push(routes.routes.desktopActivationSuccess.path)
  }, [history, scriptApplied, loading])

  return (
    <DesktopActivationView
      showAnimation={!!cookiesPolicyAccepted && (cookiesPolicyAccepted === 'yes')}
      onGifLoaded={onGifLoaded}
    />
  )
}

export default DesktopActivation
