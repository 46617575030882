export const getCookie = (name: string) => {
  const pattern = RegExp(name + "=.[^;]*");
  const matched = document.cookie.match(pattern);
  // cookie doesn't exist
  if (!matched) return "";
  // return cookie
  const cookie = matched[0].split("=");
  return cookie[1];
};

export const getMobileOS = (): "android" | "ios" | "other" => {
  const ua = navigator.userAgent;

  if (/android/i.test(ua)) {
    return "android";
  }

  if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "ios";
  }

  return "other";
};
