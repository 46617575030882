import React, { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import messagesEN from '../../i18n/en.json'
import messagesES from '../../i18n/es.json'
import messagesCA from '../../i18n/ca.json'
import config from '../../config'

type tMessages = {
  [key: string]: Record<string, string> | undefined
}
const fallbackTranslations: tMessages = {
  en: messagesEN,
  es: messagesES,
  ca: messagesCA,
}

type tProps = { children: React.ReactNode }

function TranslationProvider (props: tProps) {
  const params = (new URL(window.location.toString())).searchParams
  let lang = params.get('lang') || 'es'

  if (!lang || !fallbackTranslations[lang]) lang = 'es'

  const [messages, setMessages] = useState(fallbackTranslations[lang])
  const [messagesLoading, setMessagesLoading] = useState(true)

  useEffect(() => {
    setMessagesLoading(true)
    const getTranslation = async () => {
      try {
        let response = await fetch(config.translationsUrls[lang])
        let translations = await response.json()

        setMessages({ ...fallbackTranslations[lang], ...translations})
        setMessagesLoading(false)
      } catch (e) {
        console.log(e)
      }
    }

    getTranslation()
  }, [lang])

  // TODO add loader later on when messages are loading
  return (
    <IntlProvider
      locale={lang}
      key={lang}
      messages={messages}
    >
      {messagesLoading ? null : props.children}
    </IntlProvider>
  )
}

export default TranslationProvider
