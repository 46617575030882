import styled from 'styled-components'
import { background, BackgroundProps, ColorProps, space, SpaceProps } from 'styled-system'
import theme from '../../../styles/theme'

const Footer = styled.div<ColorProps & SpaceProps & BackgroundProps>`
  flex: 1;
  max-height: 55px;
  width: 100%;
  background: ${theme.colors.green2};
  ${space};
  ${background};
`

export default Footer
