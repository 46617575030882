import React from 'react'
import { ThemeProvider } from 'styled-components'

import Router from '../Router'
import TranslationProvider from '../TranslationProvider'

import theme from '../../styles/theme'
import { GlobalStyles } from '../../styles/GlobalStyle'

import AppWrapperView from '../../components/blocks/Cookification/AppWrapperView'
import VideoBg from '../../components/VideoBg'

const App = () => {
  return (
    <TranslationProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <VideoBg>
          <AppWrapperView>
            <Router />
          </AppWrapperView>
        </VideoBg>
      </ThemeProvider>
    </TranslationProvider>
  )
}

export default App
