import React from 'react'
import OpenOnComputerView from './OpenOnComputerView'

const OpenOnComputer = () => {
  return (
    <OpenOnComputerView />
  )
}

export default OpenOnComputer
