import React, { FC } from 'react'
import { Typography } from '../../Typography'
import theme from '../../../styles/theme'
import Tile from '../Tile'
import Icon from '../../Icon'
import { Box, Flex } from 'reflexbox/styled-components'
import { FormattedMessage } from 'react-intl'

const Footer: FC = () =>
  <Box mt={'xxxxl'}>
    <Box textAlign={'center'}>
      <Typography
        color={'white'}
        variant={'label4'}
        style={{ textTransform: 'uppercase' }}
      >
        <FormattedMessage id='cookificationFooter.howItWorks' />
      </Typography>
      <Box py={'xl'}>
        <Icon src='/images/arrowDown.svg' />
      </Box>
    </Box>
    <Flex
      flexDirection={'row'}
      flexWrap={'wrap'}
      flex={1}
      width={'100%'}
      maxWidth={theme.breakpoints.xl}
      justifyContent={'flex-start'}
      mt={'xl'}
      mb={'xxxxl'}
    >

      <Tile>
        <Tile.IconContainer>
          <Icon width={'90px'} height={'90px'} src='/images/iconCookie.svg' />
        </Tile.IconContainer>
        <Tile.Title>
          <FormattedMessage id='cookificationFooter.installingCookie' />
        </Tile.Title>
        <Tile.Description>
          <FormattedMessage id='cookificationFooter.installingCookieDescription' />
        </Tile.Description>
      </Tile>

      <Tile>
        <Tile.IconContainer>
          <Box>
            <Typography
              as={'span'}
              color={'white'}
              fontSize={80}>
              60
            </Typography>
            <Typography
              as={'span'}
              color={'white'}
              variant={'label9'}
              fontWeight={500}
              letterSpacing={1.5}
              style={{ textTransform: 'uppercase' }}
            >
              <FormattedMessage id='cookificationFooter.days' />
            </Typography>
          </Box>
        </Tile.IconContainer>
        <Tile.Title>
          <FormattedMessage id='cookificationFooter.profileActiveFor60days' />
        </Tile.Title>
        <Tile.Description>
          <FormattedMessage id='cookificationFooter.profileActiveFor60daysDescription' />
        </Tile.Description>
      </Tile>

      <Tile>
        <Tile.IconContainer>
          <Icon width={'60px'} height={'60px'} src='/images/specialKLogo.png' />
          <Icon width={'50px'} height={'65px'} src='/images/pringlesLogoBlanco.png' />
          <Icon width={'80px'} height={'20px'} src='/images/iberiaLogoBlanco.png' />
        </Tile.IconContainer>
        <Tile.Title>
          <FormattedMessage id='cookificationFooter.selectedBrands' />
        </Tile.Title>
        <Tile.Description>
          <FormattedMessage id='cookificationFooter.selectedBrandsDescription' />
        </Tile.Description>
      </Tile>

      <Tile>
        <Tile.IconContainer>
          <Icon width={'90px'} height={'90px'} src='/images/miIdBig.svg' />
        </Tile.IconContainer>
        <Tile.Title>
          <FormattedMessage id='cookificationFooter.weeklyPoints' />
        </Tile.Title>
        <Tile.Description>
          <FormattedMessage id='cookificationFooter.weeklyPointsDescription' />
        </Tile.Description>
      </Tile>

      <Tile>
        <Tile.IconContainer>
          <Icon width={'90px'} height={'90px'} src='/images/iconCalendarioWhite.svg' />
        </Tile.IconContainer>
        <Tile.Title>
          <FormattedMessage id='cookificationFooter.expireNotifications' />
        </Tile.Title>
        <Tile.Description>
          <FormattedMessage id='cookificationFooter.expireNotificationsDescription' />
        </Tile.Description>
      </Tile>
    </Flex>
  </Box>

export default Footer
