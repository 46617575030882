import React from "react";
import { Flex } from "reflexbox/styled-components";
import { FormattedMessage } from "react-intl";

import { CardBody } from "../../components/Typography";
import Cookification from "../../components/blocks/Cookification";
import SpeechCoachBox from "../../components/blocks/SpeechCoachBox";
import Button from "../../components/Button";

interface ActivationView {
  clickFn: () => void;
}

const ActivationView = ({ clickFn }: ActivationView) => (
  <Cookification>
    <Flex
      flex={1}
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-end"
      height="100vh"
      px="xl"
      py="xxxl"
      pb="80px"
    >
      <SpeechCoachBox
        claimId="mobileActivation.upperClaim"
        messageId="mobileActivation.title"
      />

      <Flex
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        pt={"xl"}
        mt={110}
      >
        <CardBody color="white">
          <FormattedMessage id="mobileActivation.weWillRedirectYouToApp" />
        </CardBody>

        <Flex py="xl" width="100%" justifyContent={"center"}>
          <Button onClick={clickFn}>
            <FormattedMessage id="mobileActivation.goBackToApp" />
          </Button>
        </Flex>
      </Flex>
    </Flex>
  </Cookification>
);

export default ActivationView;
