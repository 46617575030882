import styled, { withTheme } from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'
import React from 'react'
import { Typography } from '../../Typography'

const StyledLabel = styled.div<ColorProps & SpaceProps>`
  border: 1px solid ${props => props.color && props.color};
  border-radius: 3px;
  padding: 0 5px;
  text-transform: uppercase;
  ${color};
  ${space};
`

const Label = withTheme(({ children, ...props }) => (
  <StyledLabel mb={'s'} {...props}>
    <Typography as={'span'} variant={'label10'} color={props.color}>
      {children}
    </Typography>
  </StyledLabel>)
)

export default Label
