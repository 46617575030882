interface IBreakpoint extends Array<string> {
  xs?: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
}

const breakpoints: IBreakpoint = ['0px', '576px', '768px', '992px', '1200px']
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

interface ISpace extends Array<number> {
  xxs?: number
  xs?: number
  s?: number
  m?: number
  l?: number
  xl?: number
  xxl?: number
  xxxl?: number
  xxxxl?: number
  xxxxxl?: number
}

const space: ISpace = [0, 8, 10, 12, 15, 20, 25, 50, 70]

space.xxs = space[0]
space.xs = space[1]
space.s = space[2]
space.m = space[3]
space.l = space[4]
space.xl = space[5]
space.xxl = space[6]
space.xxxl = space[7]
space.xxxxl = space[8]
space.xxxxxl = space[9]

interface IFontSizes extends Array<number> {
  xxs?: number
  xs?: number
  s?: number
  m?: number
  l?: number
  xl?: number
  xxl?: number
  xxxl?: number
  xxxxl?: number
  xxxxxl?: number
}

const fontSizes: IFontSizes = [11, 12, 14, 16, 18, 20, 24, 30, 50]

fontSizes.xxs = fontSizes[0]
fontSizes.xs = fontSizes[1]
fontSizes.s = fontSizes[2]
fontSizes.m = fontSizes[3]
fontSizes.l = fontSizes[4]
fontSizes.xl = fontSizes[5]
fontSizes.xxl = fontSizes[6]
fontSizes.xxxl = fontSizes[7]
fontSizes.xxxxl = fontSizes[8]
fontSizes.xxxxxl = fontSizes[9]

export default {
  breakpoints,
  space,
  fontSizes,
  colors: {
    dark: '#2b2d2e',
    white: '#ffffff',
    green: '#57b093',
    green2: '#4da287',
    grey: '#c7d1d4',
    grey3: '#a0a4a6',
    // NEW v5.0 RESTYLING
    lightBlue1: '#85D4C4',
    lightGrey1: '#979CAE',
    lightGrey3: '#F5F7FE',
  }
}
