import React, { FC } from 'react'
import DesktopActivationSuccessView from './DesktopActivationSuccessView'

const DesktopActivationSuccess: FC = () => {

  return (
    <DesktopActivationSuccessView />
  )
}

export default DesktopActivationSuccess
