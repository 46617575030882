import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, button, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, input {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
*:focus {
    outline:none !important;
}
* {
    outline: 0 !important;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('./fonts/Montserrat-Regular.ttf');
    font-display: fallback;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('./fonts/Montserrat-Medium.ttf');
    font-display: fallback;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 600;
    src: url('./fonts/Montserrat-SemiBold.ttf');
    font-display: fallback;
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('./fonts/Montserrat-Bold.ttf');
    font-display: fallback;
}

@font-face {
    font-family: 'Mulish-Bold';
    font-weight: 700;
    src: url('./fonts/Mulish-Bold.ttf');
    font-display: fallback;
}

@font-face {
    font-family: 'Mulish-ExtraBold';
    font-weight: 900;
    src: url('./fonts/Mulish-ExtraBold.ttf');
    font-display: fallback;
}

@font-face {
    font-family: 'Mulish-Regular';
    font-weight: 400;
    src: url('./fonts/Mulish-Regular.ttf');
    font-display: fallback;
}

html {
    font-family: Mulish-Bold, sans-serif;
    font-weight: 700;
    font-size: 14px;
    -webkit-font-smoothing: antialiased;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #85D4C470;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 15px #85D4C400;
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #85D4C400;
	}
}
`
