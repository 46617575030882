import React from 'react'
import { Flex } from 'reflexbox/styled-components'

import Cookification from '../../components/blocks/Cookification'
import SpeechCoachBox from '../../components/blocks/SpeechCoachBox'

const SetMobileCookieView = () => {
  return (
    <Cookification>
      <Flex
        flex={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-end"
        height="100vh"
        px="xl"
        py="xxxl"
        pb="200px"
        style={{
          boxSizing: 'border-box'
        }}
      >
        <SpeechCoachBox messageId='mobilePlanting.title' />
      </Flex>
    </Cookification>
  )
}

export default SetMobileCookieView
