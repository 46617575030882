import React, { FC, createContext, useEffect, useState, useRef } from 'react'
import * as Sentry from '@sentry/react'
import styled from 'styled-components'

interface IVideoProps {
    videoLoaded: boolean;
    videoTransition: boolean;
}

const PATHS_WITH_VIDEO = [
    '/setmobilecookie',
    '/activation'
]

const VIDEO_SOURCES = [
    'https://firebasestorage.googleapis.com/v0/b/tribaldataprod.appspot.com/o/videos%2Ftreeplanted_01.mp4?alt=media&token=6ad5e4f2-c843-44e8-a805-a5403c56389c',
    'https://firebasestorage.googleapis.com/v0/b/tribaldataprod.appspot.com/o/videos%2Ftreeplanted_02.mp4?alt=media&token=f66b52c3-0d14-4e1d-9d29-5eeed300f419',
    'https://firebasestorage.googleapis.com/v0/b/tribaldataprod.appspot.com/o/videos%2Ftreeplanted_03.mp4?alt=media&token=3b246897-64b7-4095-9225-8c877ff5d961',
    'https://firebasestorage.googleapis.com/v0/b/tribaldataprod.appspot.com/o/videos%2Ftreeplanted_04.mp4?alt=media&token=2d762d6d-54c9-4ae3-b730-8f5bc9a5515d',
    'https://firebasestorage.googleapis.com/v0/b/tribaldataprod.appspot.com/o/videos%2Ftreeplanted_05.mp4?alt=media&token=2ac6d89f-a1c6-41e5-b889-d334a6664e4d',
]

const InitialVideoProps: IVideoProps = {
    videoLoaded: false,
    videoTransition: false,
}

const Video = styled.video`
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    background: #131C21;
`

export const VideoContext = createContext(InitialVideoProps)

const VideoBg: FC = ({ children }) => {

    const searchParams = window.location.search
    const params = new URLSearchParams(searchParams)
    const tpParam = params.get('tp')
    let treesPlanted = (tpParam != null && (tpParam !== 'null'))
        ? parseInt(tpParam)
        : 5

    if (isNaN(treesPlanted)) {
        Sentry.captureException(new Error('Trees planted variable not a number'))
        treesPlanted = 5
    }

    let videoSrc = VIDEO_SOURCES[treesPlanted > 4
        ? Math.floor(Math.random() * 5)
        : treesPlanted
    ]

    if (!VIDEO_SOURCES.includes(videoSrc)) {
        Sentry.captureException(new Error('Video source not valid'))
        videoSrc = VIDEO_SOURCES[4]
    }

    const [videoProps, setVideoProps] = useState(InitialVideoProps)

    const videoPlayer = useRef<HTMLVideoElement>(null);

    const canPlayHandler = () => {
        if (videoProps.videoLoaded) {
            return
        }
        console.timeEnd('videoReady')
        setVideoProps({ ...videoProps, ...{ videoLoaded: true } })
    }

    const onTimeUpdateHandler = () => {
        if ((videoPlayer?.current?.currentTime || 0) > 9.5) {
            setVideoProps({ ...videoProps, ...{ videoTransition: true } })
        }
    }

    useEffect(() => {
        console.time('videoReady')
    }, [])

    if (!PATHS_WITH_VIDEO.includes(window.location.pathname)) {
        return (<div>
            {children}
        </div>)
    }

    return (
        <VideoContext.Provider value={videoProps}>
            <Video
                ref={videoPlayer}
                autoPlay={true}
                muted={true}
                playsInline={true}
                onCanPlay={canPlayHandler}
                onTimeUpdate={onTimeUpdateHandler}
            >
                <source
                    type="video/mp4"
                    src={videoSrc || VIDEO_SOURCES[0]}
                />
            </Video>
            {children}
        </VideoContext.Provider>
    )
}

export default VideoBg
