import styled, { css } from 'styled-components'
import themCss from '@styled-system/css'
import {
	TypographyProps,
	SpaceProps,
	typography,
	color,
	layout,
	compose,
	flexbox,
	border,
	space,
	background,
	variant
} from 'styled-system'

const styledSystemProps = compose(
  typography,
  color,
  space,
  layout,
  flexbox,
  border,
  background
)

const TextStyles = css`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 1;
  ${themCss({
    fontSize: 'l',
    color: 'dark'
  })}
`

const Text = styled.div<TypographyProps & SpaceProps>`
  ${TextStyles};
  ${styledSystemProps};
`

const TitleStyles = css`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 35px;
  ${themCss({
    fontSize: 'xxxl',
    color: 'dark'
  })}
`

const Title = styled.h1<SpaceProps & TypographyProps>`
  ${TitleStyles}
  ${styledSystemProps}
`

const Anchor = styled.a<TypographyProps>`
  ${themCss({
    color: 'green'
  })}
  ${styledSystemProps}
`

// NEW TYPO FOR v5.0 RESTYLING
const ClaimStyles = css`
  font-family: Mulish-ExtraBold, sans-serif;
  font-weight: 900;
  text-align: center;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 10px;
  ${themCss({
    fontSize: 's',
    color: 'lightBlue1'
  })}
`

const Claim = styled.div<TypographyProps & SpaceProps>`
  ${ClaimStyles};
  ${styledSystemProps};
`

const CardTitleStyles = css`
  font-family: Mulish-Bold, sans-serif;
  font-weight: 700;
  text-align: center;
  line-height: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
  ${themCss({
    fontSize: 'xxl',
    color: 'dark',
  })}
`

const CardTitle = styled.div<TypographyProps & SpaceProps>`
  ${CardTitleStyles};
  ${styledSystemProps};
`

const CardBodyStyles = css`
  font-family: Mulish, sans-serif;
  font-weight: 400;
  text-align: center;
  line-height: 20px;
  ${themCss({
    fontSize: 'l',
    color: 'dark',
  })}
`

const CardBody = styled.div<TypographyProps & SpaceProps>`
  ${CardBodyStyles};
  ${styledSystemProps};
`
// END OF NEW TYPO FOR v5.0 RESTYLING

const TypographyVariants = {
  label1: { fontSize: '30px' },
  label4: { fontSize: '18px' },
  label5: { fontSize: '16px' },
  label6: { fontSize: '14px' },
  label9: { fontSize: '11px' },
  label10: { fontSize: '11px', letterSpacing: 1 }
}

const Typography = styled.div<{variant?: string} & TypographyProps & SpaceProps>`
  ${TextStyles};
  ${styledSystemProps};
  ${variant({
    variants: TypographyVariants
  })}
`

export {
  TextStyles,
  Text,
  TitleStyles,
  Title,
  Typography,
  Anchor,
  // NEW TYPO FOR v5.0 RESTYLING
  Claim,
  CardTitle,
  CardBody,
}
