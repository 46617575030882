import React from 'react'
import { Box } from 'reflexbox'
import { withTheme } from 'styled-components'

const Content = withTheme(({ children, ...props }) => (
  <Box
    width={'100%'}
    display={'flex'}
    flex={1}
    flexDirection={'row'}
    p={'m'}
    {...props}>
      {children}
  </Box>)
)

export default Content
