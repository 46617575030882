import React, { FC } from 'react'
import { Flex } from 'reflexbox/styled-components'

const IconContainer: FC = ({ children, ...props }) =>
  <Flex
    minHeight={90}
    alignItems={'center'}
    justifyContent={'space-around'}
    textAlign={'center'}
    mb={'xl'}
    {...props}
  >
    {children}
  </Flex>

export default IconContainer
