import React, { FC, useCallback } from "react";
import { useLocation } from "react-router";
import ActivationView from "./ActivationView";
import config from "../../config";
import { mobileDeepLink } from "../../enums";
import { getCookie, getMobileOS } from "../../utils";

const MobileActivation: FC = () => {
  const searchParams = useLocation().search;
  const params = new URLSearchParams(searchParams);

  const redirectToLink = useCallback(() => {
    const btrid = getCookie("_btrid");
    const os = getMobileOS();
    const env = params.get("env");
    const useIntent = params.get("intent");
    const intentUri = window.location.hostname;

    if (useIntent != null && os !== "ios") {
      window.open(`https://${intentUri}/store/accept?btrid=${btrid}`);
      return;
    }

    let deepLink: string = config.mobileAppDeepLink;

    if (env && env in mobileDeepLink) {
      deepLink = env;
    }

    // Deep link URL for existing users with app already installed on their device
    // @ts-ignore
    window.location.href = `${mobileDeepLink[deepLink]}://accept?btrid=${btrid}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ActivationView clickFn={redirectToLink} />;
};

export default MobileActivation;
