import styled, { css } from 'styled-components'
import { space, color, variant, border, BorderProps, ColorProps, SpaceProps } from 'styled-system'

interface IButton extends ColorProps, SpaceProps, BorderProps {
  variant?: string,
  disabled?: boolean
}

const tapped = css`
  ${variant({
    variants: {
      filled: {
        bg: 'white',
        color: 'green',
        border: 'solid 1px grey'
      },
      outlined: {
        bg: 'green',
        color: 'white'
      }
    }
  })}
`

const disabled = css`
  ${variant({
    variants: {
      filled: {
        bg: 'grey',
        color: 'white'
      },
      outlined: {
        bg: 'white',
        color: 'grey',
        border: 'solid 1px',
        borderColor: 'grey'
      }
    }
  })}

  cursor: not-allowed;
`

const Button = styled.button<IButton>`
  ${color}
  ${space}
  ${border}
  outline: 0;
  width: 100%;
  max-width: 335px;
  padding-left: 15px;
  padding-right: 15px;
  height: 44px;
  border-radius: 22px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 300ms all;
  text-transform: uppercase;
  font-size: ${ props => props.theme.fontSizes.xs}px;
  font-weight: 600;
  letter-spacing: 2px;
  line-height: 15px;

  ${variant({
    variants: {
      filled: {
        bg: 'white',
        color: 'dark'
      },
      outlined: {
        bg: 'white',
        color: 'green',
        border: '1px solid',
        borderColor: 'green'
      }
    }
  })}

  &:hover {
   ${props => !props.disabled && tapped}
  }

  ${ props => props.disabled && disabled}
`

Button.defaultProps = {
  variant: 'filled'
}

export default Button
