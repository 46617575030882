import { mobileDeepLink } from './enums'

interface ITranslationsUrls {
  [key: string]: string
}

interface IConfig {
  translationsUrls: ITranslationsUrls
  mobileAppDeepLink: string
  cfLink: string
}

let config: IConfig = {
  translationsUrls: { },
  mobileAppDeepLink: 'tribaldata',
  cfLink: 'https://us-central1-tribaldataprod.cloudfunctions.net/activeDesktopUser'
}

const initConfig = () => {
  if (!process.env.REACT_APP_TRANSLATION_URL_EN) {
    throw new Error('REACT_APP_TRANSLATION_URL_EN not specified')
  }

  if (!process.env.REACT_APP_TRANSLATION_URL_ES) {
    throw new Error('REACT_APP_TRANSLATION_URL_ES not specified')
  }

  if (!process.env.REACT_APP_TRANSLATION_URL_CA) {
    throw new Error('REACT_APP_TRANSLATION_URL_CA not specified')
  }

  if (!process.env.REACT_APP_MOBILE_APP_DEEP_LINK) {
    throw new Error('REACT_APP_MOBILE_APP_DEEP_LINK not specified')
  }

  if (!(process.env.REACT_APP_MOBILE_APP_DEEP_LINK in mobileDeepLink)) {
    throw new Error('REACT_APP_MOBILE_APP_DEEP_LINK invalid')
  }

  if (!process.env.REACT_APP_CF_LINK) {
    throw new Error('REACT_APP_CF_LINK not specified')
  }

  config = {
    ...config,
    cfLink: process.env.REACT_APP_CF_LINK,
    mobileAppDeepLink: process.env.REACT_APP_MOBILE_APP_DEEP_LINK,
    translationsUrls: {
      en: process.env.REACT_APP_TRANSLATION_URL_EN,
      es: process.env.REACT_APP_TRANSLATION_URL_ES,
      ca: process.env.REACT_APP_TRANSLATION_URL_CA,
    }
  }
}

initConfig()

export default config
