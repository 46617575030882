import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { Anchor, Typography } from '../../components/Typography'
import Cookification from '../../components/blocks/Cookification'
import Button from '../../components/Button'
import { FormattedMessage } from 'react-intl'
import CardBox from '../../components/blocks/CardBox'

interface ISetDesktopCookieInfo {
  cookiePolicyAccepted: boolean
  onButtonClick: () => void
}

const SetDesktopCookieView = ({
  onButtonClick,
  cookiePolicyAccepted
}: ISetDesktopCookieInfo) => {
  return (
    <Cookification
      backgroundImage="url('/images/bgCookificationDesktop@3x.jpg')"
    >
      <Flex
        flex={1}
        minHeight={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        height={'100%'}
        px={'xl'}
        py={'xxxl'}
      >
        <Flex
          maxWidth={500}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
          py={'xl'}
        >

          <CardBox
            mt={'xxxl'}
            mb={'xxxxl'}
          >
            <CardBox.Content>
              <CardBox.IconContainer mr={'s'}>
                <Cookification.Icon
                  width={45}
                  height={45}
                  src='/images/iconMaskProfile.png'
                  alt='mask icon'
                />
              </CardBox.IconContainer>
              <Box>
                <CardBox.Label color={'white'}>
                  <FormattedMessage id='setDesktopCookie.activateNow' />
                </CardBox.Label>
                <Typography as={'span'} variant={'label6'}>
                  <FormattedMessage id='for60days' />
                </Typography>
              </Box>
            </CardBox.Content>
            <CardBox.Footer />
          </CardBox>
          <Typography
            as='h1'
            color='white'
            variant='label1'
            my={'xl'}
          >
            <FormattedMessage id='setDesktopCookie.activatingTribeCard' />
          </Typography>
          <Typography
            color={'white'}
            py={'l'}
            px={'xxl'}
            variant='label4'
          >
            <FormattedMessage id='setDesktopCookie.improveRelevanceOfTheAds' />
          </Typography>
        </Flex>
        <Typography
          color={'white'}
          py={'xxl'}
          variant='label6'
          fontWeight={'500'}
        >
          <FormattedMessage id='setDesktopCookie.readOur' />
          <Anchor target='_blank' href='https://tribaldata.es/politica-de-cookies/'>
            <FormattedMessage id='setDesktopCookie.cookiesPolicy' />
          </Anchor>
        </Typography>
        <Flex
          pt='xl'
          pb={'xxl'}
          width='100%'
          justifyContent={'center'}
        >
          <Button
            onClick={onButtonClick}
            disabled={!cookiePolicyAccepted}
          >
            <FormattedMessage id='setDesktopCookie.activate' />
          </Button>
        </Flex>
        <Cookification.Footer />
      </Flex>
    </Cookification >
  )
}

export default SetDesktopCookieView
