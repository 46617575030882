import React from 'react'
import { Flex, Box } from 'reflexbox/styled-components'
import { Typography } from '../../components/Typography'
import Cookification from '../../components/blocks/Cookification'
import { FormattedMessage } from 'react-intl'
import Icon from '../../components/Icon'

const OpenOnComputerView = () => {
  return (
    <Cookification
      backgroundImage={"url('/images/forComputer@3x.jpg')"}
    >
      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
        px={'xl'}
        py={'xxxl'}
      >
        <Box
          flex={1}
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          maxWidth={{ xs: '300px', lg: '400px' }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            my={'xxxxl'}
          >
            <Icon
              width={100}
              height={100}
              src='images/miIdBig.svg'
              style={{
                transform: 'rotate(-15deg)'
              }}
            />
          </Box>
          <Typography
            as='h1'
            color='white'
            variant={'label1'}
            lineHeight={1.2}
            mb={'xl'}
          >
            <FormattedMessage id='forComputer.openThisLinkOnComputer' />
          </Typography>
          <Typography
            as='p'
            color='white'
            variant={'label5'}
            lineHeight={1.2}
          >
            <FormattedMessage id='forComputer.openThisLinkOnComputerDescription' />
          </Typography>
        </Box>
      </Flex>
    </Cookification>
  )
}

export default OpenOnComputerView
