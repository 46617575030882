import Container from './Container'
import IconContainer from './IconContainer'
import Title from './Title'
import Description from './Description'

type TileProps = typeof Container & {
  IconContainer: typeof IconContainer
  Title: typeof Title
  Description: typeof Description
}

const Tile = Container as TileProps

Tile.IconContainer = IconContainer
Tile.Title = Title
Tile.Description = Description

export default Tile
