import React from 'react'
import { Box, Flex } from 'reflexbox/styled-components'
import { Typography } from '../../components/Typography'
import Cookification from '../../components/blocks/Cookification'
import { FormattedMessage } from 'react-intl'
import CardBox from '../../components/blocks/CardBox'

const DesktopActivationSuccessView = () => {
  return (
    <Cookification
      backgroundImage="url('/images/bgCookificationDesktop@3x.jpg')"
    >
      <Flex
        flex={1}
        minHeight={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        height={'100%'}
        px={'xl'}
        py={'xxxl'}
      >
        <Flex
          maxWidth={600}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
          mb={'xxxxl'}
        >

          <CardBox
            mt={'xxxl'}
            mb={'xxxxl'}
          >
            <CardBox.Content>
              <CardBox.IconContainer mr={'s'}>
                <Cookification.Icon
                  width={45}
                  height={45}
                  src='/images/iconMaskProfile.png'
                  alt='mask icon'
                />
              </CardBox.IconContainer>
              <Box>
                <CardBox.Label color={'white'}>
                  <FormattedMessage id='desktopActivationSuccess.active' />
                </CardBox.Label>
                <Typography as={'span'} variant={'label6'}>
                  <FormattedMessage id='for60days' />
                </Typography>
              </Box>
            </CardBox.Content>
            <CardBox.Footer />
          </CardBox>
          <Typography
            as='h1'
            color='white'
            variant='label1'
            my={'xl'}
          >
            <FormattedMessage id='desktopActivationSuccess.congratulations' /> <br/><br/>
            <FormattedMessage id='desktopActivationSuccess.activeFor60days' />
          </Typography>
        </Flex>

        <Cookification.Footer />
      </Flex>
    </Cookification >
  )
}

export default DesktopActivationSuccessView
