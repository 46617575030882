import styled from 'styled-components'
import { color, ColorProps, space, SpaceProps } from 'styled-system'

const IconContainer = styled.div<ColorProps & SpaceProps>`
  ${space}
  ${color}
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
`

IconContainer.defaultProps = {
  bg: 'white'
}

export default IconContainer
