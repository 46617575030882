import React from 'react'
import ReactDOM from 'react-dom'
import App from './containers/App'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import * as serviceWorker from './serviceWorker'

Sentry.init({
  dsn: 'https://b833e89148ef449da7c410f53071fe8c@o4504877552369664.ingest.sentry.io/4504877937197056',
  environment: process.env.NODE_ENV,
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
  integrations: [new BrowserTracing(), new Sentry.Replay()],
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
