import styled from 'styled-components'
import { color, space, ColorProps, SpaceProps } from 'styled-system'

const IconContainer = styled.div<ColorProps & SpaceProps>`
  ${space}
  ${color}
  width: 120px;
  height: 120px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

IconContainer.defaultProps = {
  bg: 'white'
}

export default IconContainer
