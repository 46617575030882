import styled from 'styled-components'
import { background, BackgroundProps, LayoutProps, space, SpaceProps } from 'styled-system'
import theme from '../../../styles/theme'

const CardBox = styled.div<BackgroundProps & LayoutProps & SpaceProps>`
  background: ${theme.colors.green};
  min-height: 200px;
  width: 100%;
  max-width: 355px;
  max-height: 220px;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(0,0,0,0.5);
  overflow: hidden;
  ${background};
  ${space};
`

export default CardBox
