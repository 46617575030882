import React from 'react'
import { Flex } from 'reflexbox/styled-components'
import { Typography } from '../../components/Typography'
import Cookification from '../../components/blocks/Cookification'
import { FormattedMessage } from 'react-intl'

interface IDesktopActivationViewProps {
  showAnimation: boolean
  onGifLoaded: () => void
}

const DesktopActivationView = (props: IDesktopActivationViewProps) => {
  return (
    <Cookification
      backgroundImage="url('/images/bgCookificationDesktop@3x.jpg')"
    >
      <Flex
        flex={1}
        minHeight={'100%'}
        flexDirection={'column'}
        alignItems={'center'}
        height={'100%'}
        px={'xl'}
        py={'xxxl'}
      >
        <Flex
          maxWidth={500}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          textAlign={'center'}
          mb={'xxxl'}
        >
          {props.showAnimation ? <Cookification.Icon
            src='/images/mask.gif'
            alt='mask icon'
            mt='xxxl'
            mb='xxxxl'
            width={120}
            height={120}
            onLoad={props.onGifLoaded}
          /> : <Cookification.IconContainer
            mt='xxxl'
            mb='xxxxl'
          >
            <Cookification.Icon
              src='/images/iconMaskProfile.png'
              alt='mask icon'
            />
          </Cookification.IconContainer>}
          <Typography
            as='h1'
            color='white'
            variant='label1'
            my={'xxxxl'}
          >
            <FormattedMessage id='desktopActivation.activation' />
          </Typography>
        </Flex>

        <Cookification.Footer />
      </Flex>
    </Cookification >
  )
}

export default DesktopActivationView
