import Icon from './Icon'
import IconContainer from './IconContainer'
import OriginalCookification from './Cookification'
import Footer from './Footer'

type CookificationProps = typeof OriginalCookification & {
  Icon: typeof Icon
  IconContainer: typeof IconContainer
  Footer: typeof Footer
}

const Cookification = OriginalCookification as CookificationProps

Cookification.Icon = Icon
Cookification.IconContainer = IconContainer
Cookification.Footer = Footer

export default Cookification
