import styled from 'styled-components'

const Pulse = styled.div`
    position: absolute;
	border-radius: 50%;
	height: 150px;
	width: 150px;
	box-shadow: 0 0 0 0 #EAFBF7;
	transform: scale(1);
	animation: pulse 2s infinite;
`

export const PulseSmall = styled.div`
	border-radius: 50%;
	height: 70px;
	width: 70px;
	margin: 35px auto;
	box-shadow: 0 0 0 0 #70F1D2;
	transform: scale(1);
	animation: pulse 3s infinite;
`

export default Pulse
