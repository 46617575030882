import styled from 'styled-components'
import { layout, space, LayoutProps, SpaceProps } from 'styled-system'

const Icon = styled.img<LayoutProps & SpaceProps>`
  width: 150px;
  height: 150px;
  ${layout};
  ${space};
`

export default Icon
