import styled from 'styled-components'

const RoundedCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 500px;
    background: white;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    margin: 100px auto 0;
    padding: 30px 25px;
    position: relative;
`

export default RoundedCard
