import React, { FC, useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useHistory, useLocation } from 'react-router'
import { VideoContext } from '../../components/VideoBg'
import Loading from '../../components/blocks/Cookification/Loading'
import SetMobileCookieView from './SetMobileCookieView'
import routes from '../../containers/Router/routes'
import { getCookie } from '../../utils'

const SetMobileCookie: FC = () => {
  const searchParams = useLocation().search
  const params = new URLSearchParams(searchParams)
  const cookiesPolicyAccepted = params.get('cookie_policy_accepted')
  const history = useHistory()
  const videoProps = useContext(VideoContext)
  const [scriptApplied, setScriptApplied] = useState(false)
  const [bypassVideo, setBypassVideo] = useState(false)

  const SITE_ID: { [key: string]: string } = {
    'El_Nacional_WWcLTyqK80HY6TiK9L0': '7708',
    td: '2777'
  }

  useEffect(() => {
    const setCookie = () => {
      try {
        const btrid = getCookie('_btrid')
        const comID = params.get('comid') || 'td'
        const tribalDataId = params.get('sha')
        const dscript = document.createElement('script')
        dscript.src = `https://dmp.citiservi.es/pxtrack.js?mode=external&m=2&sid=${SITE_ID[comID] || '2777'}&p=1n7h88h3&i=2778&bt=${btrid}&id=${tribalDataId}`
        document.head.appendChild(dscript)
      } catch {
        Sentry.captureException(new Error('Citiservi script was not loaded'))
      }
      setScriptApplied(true)
    }

    setTimeout(() => {
      setBypassVideo(true)
    }, 10000)

    if (!cookiesPolicyAccepted || (cookiesPolicyAccepted !== 'yes')) {
      return
    }

    setCookie()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if ((videoProps.videoTransition || bypassVideo) && scriptApplied) {
      history.push(routes.routes.activation.path + searchParams)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoProps.videoTransition, scriptApplied, bypassVideo])

  return (
    videoProps.videoLoaded
      ? <SetMobileCookieView />
      : <Loading />
  )
}

export default SetMobileCookie
