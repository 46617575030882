import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { useLocation } from 'react-router'

import { CardTitle, Claim } from '../../Typography'
import RoundedCard from '../Cookification/RoundedCard'
import Icon from '../../Icon'

const SpeechTriangle = styled.div`
  position: absolute;
  bottom: -18px;
  right: 80px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent white transparent transparent;
`

interface ISpeechCoachBox {
    messageId: string;
    claimId?: string;
}

const SpeechCoachBox = ({ messageId, claimId = '' }: ISpeechCoachBox) => {

    const searchParams = useLocation().search
    const params = new URLSearchParams(searchParams)
    const coachImageSlug = params.get('coach')

    const coachImg = coachImageSlug ?
        `https://images.ctfassets.net/h4yl5u9p6k3u${coachImageSlug}` : './images/coachFace.png'

    return (
        <RoundedCard>
            { Boolean(claimId.length) && (
                <Claim as='p'>
                    <FormattedMessage id={claimId} />
                </Claim>
            )}

            <CardTitle as='h3'>
                <FormattedMessage id={messageId} />
            </CardTitle>

            <SpeechTriangle />
            <Icon
                src={coachImg}
                height={120}
                width={coachImageSlug ? 110 : 75}
                style={{
                    position: 'absolute',
                    right: coachImageSlug ? -40 : -20,
                    bottom: -90,
                }}
            />
        </RoundedCard>
    )
}

export default SpeechCoachBox
