import styled from 'styled-components'
import { background, BackgroundProps } from 'styled-system'

const Cookification = styled.div<BackgroundProps>`
  min-height: 100vh;
  width: 100%;
  display: flex;
  ${background};
  background: none;
`

export default Cookification
