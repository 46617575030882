import SetMobileCookie from '../../pages/SetMobileCookie'
import NoMatch from '../../pages/NoMatch'
import MobileActivation from '../../pages/MobileActivation'
import SetDesktopCookie from '../../pages/SetDesktopCookie'
import DesktopActivation from '../../pages/DesktopActivation'
import DesktopActivationSuccess from '../../pages/DesktopActivationSuccess'
import OpenOnComputer from '../../pages/OpenOnComputer'

export interface IRoute<T = React.FunctionComponent> {
  path: string
  order: number
  component: T // could not work out a good way of managing this
}

export interface INestedRoute<T = {}> {
  path: string
  order: number
  routes: T
}

interface IHomeRoutes extends Record<string, IRoute> {
  home: IRoute
  noMatch: IRoute
}

const routes: INestedRoute<IHomeRoutes> = {
  path: '/',
  order: 1,
  routes: {
    home: {
      path: '/setmobilecookie',
      order: 1,
      component: SetMobileCookie
    },
    activation: {
	  path: '/activation',
	  order: 1,
	  component: MobileActivation
    },
    // TODO: clean up unused routes below
    setDesktopCookie: {
      path: '/setdesktopcookie',
      order: 1,
      component: SetDesktopCookie
    },
    desktopActivation: {
      path: '/desktopActivation',
      order: 1,
      component: DesktopActivation
    },
    desktopActivationSuccess: {
      path: '/desktopActivationSuccess',
      order: 1,
      component: DesktopActivationSuccess
    },
    openOnComputer: {
      path: '/openOnComputer',
      order: 1,
      component: OpenOnComputer
    },
    noMatch: {
      path: '*',
      order: 9999, // IMPORTANT: needs to be last in the routes to not override other
      component: NoMatch
    }
  }
}

export default routes
