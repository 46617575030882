import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { CardBody } from '../../Typography'
import { PulseSmall } from '../../blocks/Cookification/Pulse'

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`

const Loading: FC = () => (
    <LoadingContainer>
        <CardBody color={'grey3'}>
            <FormattedMessage id='cookificationLoading.loading' />
        </CardBody>
        <PulseSmall />
    </LoadingContainer>
)

export default Loading
