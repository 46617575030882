import React, { FC } from 'react'
import { Box } from 'reflexbox/styled-components'

const Container: FC = ({ children, ...props }) =>
  <Box
    width={{ s: 1, md: 1 / 2, lg: 1 / 3 }}
    display={'flex'}
    justifyContent={'center'}
    px={{ xs: 's', lg: 'm' }}
    my={{ xs: 'xxl', lg: 'xxxl' }}
    {...props}
  >
    <Box
      maxWidth={330}
    >
      {children}
    </Box>
  </Box>

export default Container
