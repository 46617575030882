import React, { FC, useContext, useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import styled from 'styled-components'
import { VideoContext } from '../../VideoBg'

const MAX_WAITING_TIME = 20000

const AppWrapper = styled.div<{ pressable: boolean }>`
  position: relative;
  z-index: 2;
  pointer-events: ${props => (props.pressable ? 'all' : 'none')};
`

const AppWrapperView: FC = ({ children }) => {
    const isSetMobile = window.location.pathname.includes('setmobilecookie')
    const [pressable, setPressable] = useState(!isSetMobile)
    const videoProps = useContext(VideoContext)

    useEffect(() => {
        if (isSetMobile) {
            setTimeout(() => {
                if (!window.location.pathname.includes('activation')) {
                    Sentry.captureException(
                        new Error('User trapped on setmobilecookie screen',
                        { cause: `Current path: ${window.location.pathname}` })
                    )
                }
            }, MAX_WAITING_TIME)
        }
    }, [isSetMobile])

    useEffect(() => {
        if (videoProps.videoTransition) {
            setPressable(true)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoProps.videoTransition])

    return (
        <AppWrapper pressable={pressable}>
            {children}
        </AppWrapper>
    )
}

export default AppWrapperView
