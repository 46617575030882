import OriginalCardBox from './CardBox'
import Content from './Content'
import IconContainer from './IconContainer'
import Footer from './Footer'
import Label from './Label'

type CardBoxProps = typeof OriginalCardBox & {
  Content: typeof Content
  IconContainer: typeof IconContainer
  Footer: typeof Footer
  Label: typeof Label
}

const CardBox = OriginalCardBox as CardBoxProps

CardBox.Content = Content
CardBox.IconContainer = IconContainer
CardBox.Label = Label
CardBox.Footer = Footer

export default CardBox
