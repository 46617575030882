import React, { FC, useCallback, useMemo } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router'
import SetDesktopCookieView from './SetDesktopCookieView'
import routes from '../../containers/Router/routes'

const SetDesktopCookie: FC = () => {
  const searchParams = useLocation().search
  const params = new URLSearchParams(searchParams)
  const history = useHistory()

  const cookiesPolicyAccepted = useMemo(() => {
    const acceptedParam = params.get('cookie_policy_accepted')
    return !!(acceptedParam && acceptedParam === 'yes')
  }, [params])

  const activateCoookie = useCallback(() => {
    history.push(routes.routes.desktopActivation.path + searchParams)
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (/iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent)) {
    return <Redirect to={routes.routes.openOnComputer.path}/>
  }

  return (
    <SetDesktopCookieView
      cookiePolicyAccepted={cookiesPolicyAccepted}
      onButtonClick={activateCoookie}
    />
  )
}

export default SetDesktopCookie
